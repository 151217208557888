import gql from 'graphql-tag'
import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const CREATE_FIELDWORKV2 = questionnaireGql(/* GraphQL */ `
  mutation createFieldworkV2($input: CreateFieldworkV2Input!) {
    createFieldworkV2(input: $input) {
      ...Fieldwork
    }
  }
`)

// NOTE: be careful when replacing `gql` with `questionnaireGql` since this can break `recordAudienceChangedForIRSNotification` work. Double check if the `recordAudienceChangedForIRSNotification` is still working after the replacement.
export const UPDATE_FIELDWORKV2 = gql`
  mutation updateFieldworkV2($input: UpdateFieldworkV2Input!) {
    recordAudienceChangedForIRSNotification @client(always: true)
    updateFieldworkV2(input: $input) {
      ...Fieldwork
    }
  }
`

export const FIELDWORK_FRAGMENT = questionnaireGql(/* GraphQL */ `
  fragment Fieldwork on Fieldwork {
    surveyId
    locale {
      language
      country
    }
    samplesNeeded {
      unadjusted
      adjusted
    }
    lengthOfInterview {
      unadjusted
      adjusted
    }
    incidenceRate
    duration {
      startDate
      endDate
    }
    audience {
      __typename
      ... on CintAudience {
        countryId
      }
      ... on CustomAudience {
        countryId
        description
      }
      ... on ProlificAudience {
        countryId
        description
      }
    }
    activePresetAudience
    presetAudience {
      presetAudienceId
      name
    }
    createdDate
    sinceDate
  }
`)
